import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import { TwitterIcon } from '@app/components/icons/SocialMediaIcons';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';

const NotebookFooter = () => (
  <Container>
    <PageSection useLegacyMQ>
      <ResponsiveContainer>
        <SocialContainer>
          <SocialLink href="https://twitter.com/mubinotebook">
            <TwitterIconContainer>
              <TwitterIcon color={themeColors.lightText} width="100%" />
            </TwitterIconContainer>
            @mubinotebook
          </SocialLink>
        </SocialContainer>
        <AboutContainer>
          <strong>Notebook</strong> is a daily, international film publication.
          Our mission is to guide film lovers searching, lost or adrift in an
          overwhelming sea of content. We offer text, images, sounds and video
          as critical maps, passways and illuminations to the worlds of
          contemporary and classic film. Notebook is a MUBI publication.
        </AboutContainer>
        <ContactContainer>
          <ContactTitle>Contact</ContactTitle>
          <ContactInfo>
            If you&apos;re interested in contributing to Notebook, please see
            our&nbsp;
            <Link href="/notebook/pitching-guidelines">
              pitching guidelines.
            </Link>
            &nbsp;For all other inquiries, contact the&nbsp;
            <a href="mailto:editor@mubi.com?subject=Notebook submission">
              editorial team.
            </a>
          </ContactInfo>
        </ContactContainer>
      </ResponsiveContainer>
    </PageSection>
  </Container>
);

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
  background-color: ${props => props.theme.color.midBackground};
  font-size: 12px;
  border-top: ${props => `${props.theme.color.white} solid 1px`};

  @media (min-width: ${props => props.theme.mq.tablet}) {
    font-size: 12px;
  }
`;

const ResponsiveContainer = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    display: flex;
  }
`;

const SocialContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 25%;
  }

  @media (min-width: ${props => props.theme.mq.desktop}) {
    width: 16.66%;
    margin-left: 8.33333%;
  }

  @media (min-width: ${props => props.theme.mq.wide}) {
    width: 18.75%;
  }
`;

const TwitterIconContainer = styled.div`
  width: 15px;
  margin-right: 6px;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 18px;
  }
`;

const SocialLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-items: flex-start;
  font-weight: 500;
  color: ${props => props.theme.color.lightText};

  &:visited {
    color: ${props => props.theme.color.darkText};
  }
  &:hover {
    color: ${props => props.theme.color.darkText};
  }
`;

const AboutContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 50%;
  }

  @media (min-width: ${props => props.theme.mq.desktop}) {
    width: 33.33%;
  }

  @media (min-width: ${props => props.theme.mq.wide}) {
    width: 37.5%;
  }
`;

const ContactContainer = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 25%;
    padding: 0 5px;
  }

  @media (min-width: ${props => props.theme.mq.desktop}) {
    margin-left: 8.33%;
  }
`;
const ContactTitle = styled.h2`
  font-weight: 500;
`;
const ContactInfo = styled.p`
  a {
    color: ${props => props.theme.color.mubiBlue};
    font-weight: 500;
  }
`;

export default NotebookFooter;
